/* .carousel {
  max-width: 1400px;
  margin: auto;
  margin-top: -45px;
}
.cards__body {
  width: 50%;
  border: none;
}

.carrusel__title {
 
  font-weight: bold;
  margin-bottom: 3rem;
  text-align: left;

  text-shadow: 3px 2px 1px rgba(0, 0, 0, 0.9);
}

.cards__body a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
}


@media only screen and (min-width: 320px) and (max-width: 768px) {
  .carrusel__title {
    margin-bottom: -3rem;
    font-size: 18px;
  }
}
 */

 /* css rediseño */

/*  #carouselInterval h2 {
  width: 70vw;
    margin-left: 100px;
    margin-top: 250px;
  
} */
/* #carouselInterval,
.carousel-inner,
.carousel-item,
.carousel-item.active {
  height: 500px;
  width: 100vw;
} */
/* 
.carousel-item:nth-of-type(1) {
 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  
}
.carousel-item:nth-of-type(2) {
 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.carousel-item:nth-of-type(3) {
 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
 */
.linea-coloresHeader {
  width: 50vw;
  height: 5px;
  background-image: linear-gradient(
    90deg,
    #e30613 33%,
    #ffdd00 33%,
    #ffdd00 66.66%,
    #00a13a 66.66%
  );

  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);

  /* posición Mobile */
  margin-left: 1px;
  margin-top: 10px;
  margin-bottom: 50px;
}
/* #carouselInterval a{
    
  color: #fff;
  text-decoration: none;
  
  

} */
/* Height for devices larger than 992px */
/* @media (min-width: 992px) {
  #carouselInterval {
    margin-top: -58.59px;
   
  }
  #carouselInterval h2 {
    width: 50vw;
      margin-left: 150px;
      margin-top: 350px;
    
  }
  #carouselInterval,
  .carousel-inner,
  .carousel-item,
  .carousel-item.active {
    height: 500px;
  }
  .linea-coloresHeader {
    width: 50vw;
    margin-left: 150px;
  }
} */

/* @media only screen and (min-width: 320px) and (max-width: 768px) {

  #carouselInterval,
  .carousel-inner,
  .carousel-item,
  .carousel-item.active {
    height: 320px;
    width: 100vw;
    margin-top: -40px;
  }
  #carouselInterval h2 {
    width: 70vw;
      margin-left: 50px;
      margin-top: 230px;
      font-size: 1.3em;
    
  }
  .linea-coloresHeader {
    width: 50vw;
    margin-left: 50px;
    margin-top: -10px;
    
  }
} */


/* Rediseño */

#carouselExampleCaptions,
.carousel-inner,
.carousel-item,
.carousel-item.active { 
  margin-top: -58px;
  
  
}
.carousel h2 {
   color: #fff;
   text-align: start;
   
  }
.carousel a {
  text-decoration: none;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.9);
}
/* .carousel-item img {
  max-height: 600px;
} */
.carousel-item img {
  max-height:600px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  #carouselExampleCaptions,
.carousel-inner,
.carousel-item,
.carousel-item.active { 
  margin-top: -28px;
  
  
}


/*   .carousel-item img {
  min-height: 250px;
} */
.carousel h2 {
  font-size: 1.2em;
  
  
}

.linea-coloresHeader {
  width: 50vw;
  
  margin-top: -10px;
  margin-bottom: 1px;
}
}


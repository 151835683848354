/*Cards*/

.container-cardTM {
  width: 100%;
  display: flex;
  max-width: 720px;
  margin: auto;
}

.title-cards {
  width: 100%;
  max-width: 1080px;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  color: #7a7a7a;
}

.card-tm {
  width: 100%;
  margin: 20px;
  border-radius: 30px;
  overflow: hidden;
  background: #00a13a;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  transition: all 400ms ease-out;
  cursor: default;
}

.card-tm:hover {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3%);
}

.card-tm img {
  width: 100%;
  height: 210px;
  border-bottom: 3px solid white;
  border-radius: 28px;
}

.card-tm .contenido-card {
  padding: 15px;
  text-align: center;
}

.card-tm .contenido-card h3 {
  margin-bottom: 5px;
  color: #ffffff;
  /* font-size: 35px; */
}

.card-tm .contenido-card p {
  line-height: 1.8;
  color: #ffffff;
  /* font-size: 14px; */
  margin-bottom: 5px;
}

.card-tm .contenido-card a {
  display: inline-block;
  padding: 10px;
  margin-top: 10px;
  text-decoration: none;
  color: #2fb4cc;
  border: 1px solid #2fb4cc;
  border-radius: 4px;
  transition: all 400ms ease;
  margin-bottom: 5px;
}

.card-tm .contenido-card a:hover {
  background: #2fb4cc;
  color: #fff;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .container-cardTM {
    flex-wrap: wrap;
    width: 350px; /* Change this to orientacion */
  }
  .card-tm {
    margin: 15px;
  }
}

/*Fin-Cards*/

/* * linea con colores rojo,
amarillo,
verde */

.linea-colores {
  width: 50%;
  height: 5px;
  background-image: linear-gradient(
    90deg,
    #e30613 33%,
    #ffdd00 33%,
    #ffdd00 66.66%,
    #00a13a 66.66%
  );
  margin-bottom: 60px;
  /*espacio vertical */
  margin-top: 60px;
  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
 
}

@media only screen and (max-width: 600px) {
  div.linea-colores {
    width: 300px;
    margin-top: 50px;
    
  }
}

/* acerca de  */

.acerca__card {
  color: #002856;
  margin-top: 50px;
 
 
}

.acerca__card .card {
  background-color: #F5F5F5;
}
.acerca__card h2 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #00519E;
  
}
.acerca__card p {
  padding-right: 30px;
  font-style: oblique;
}

.acerca__card img {
  height: 65vh;
  width: 100vw;
}

#acerca_img {
  background-image: url("https://apiwebtm.com/uploads/Acerca_de_4f7942ad24.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  #acerca_img {
    /* width: 50vw; */
    height: 300px;
    /* margin: 0 auto; */
  }


}

/* Misión y Visión */
.card__mision {
  margin-left: -12px;
  margin-top: 50px;
  
}


.card__mision .baseTab{
 
  background-color: #F5F5F5;
  cursor: pointer;
  width: 120px;
  height: 50px;
  margin: 50px 0 20px 0;
  /* padding: 20px 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
 /*  box-shadow: 0 5px 20px rgba(0,0,0,0.5);
  transition: 0.3s ease-in-out; */
  border-radius: 1px;
 font-weight: bold;
 color:#002856;
 font-size: 30px;
}

.card__mision .baseTab:hover{
  background-color: #002856 !important;
  color: white !important;
}



.card__mision .activeTab{
  background-color: #002856 !important;
  color: white !important;
  
}

.card__mision p{
  color:#002856;
}
.card__mision span{
  font-weight: bold;
}

.card__mision #mision_img {
  background-image: url("https://apiwebtm.com/uploads/Mision_9b60965b6a.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 300px;
}


@media only screen and (min-width: 0px) and (max-width: 768px) {
/*   .acerca__card img {
    margin: 0 auto;
    display: block;
    width: 70vw;
    height: 50vw;
  }
 
  .acerca__card {
    width: 80%;
  } */
}

/* Asamblea */
.asamblea {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #002856;
}

.asamblea h2 {
  margin-top: 20px;
  
  color: #00519E;
  font-weight: bold;
}

.asamblea img {
  width: 100%;
  height: auto;
}




/* Objetivos Estrategicos*/

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.obje__Estra .card {
  background-color: #F5F5F5;
  
}

.obje__Estra .baseTab{
 
  background-color: #fff;
  cursor: pointer;
  width: 120px;
  height: 100px;
  margin: 0 5px 50px 0;
  /* padding: 20px 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 20px rgba(0,0,0,0.5);
  transition: 0.3s ease-in-out;
  border-radius: 20px;
 
}

.obje__Estra .baseTab:hover{
  background-color: #002856 !important;
  color: white !important;
}
.obje__Estra .baseTab:hover i {
  color: #fff !important;
}


.obje__Estra .activeTab{
  background-color: #002856 !important;
  color: white !important;
  
}
.obje__Estra .activeTab i{
 
  color: white !important;
}


.obje__Estra .lis-group li {
  color: #002856;
  font-size: 20px;
  counter-increment: item;
  display: block;
  text-indent: -1.3rem;
}

/* ------------ */
.obje__Estra .lis-group ol {
  counter-reset: item;
}

.obje__Estra .lis-group li:before {
 content: counter(item) ".";
 font-weight: bold;
 padding-right: .5rem; 
 text-align: right;
}
.obje__Estra h2 {
  font-weight: bold;
  color: #00519E;
 margin-top: 50px;
 
}
#estra_img{
 /*  background-image: url("https://apiwebtm.com/uploads/Obj_Estra_743c30512f.png"); */
/*   background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; */
  height: 400px;
  margin-top: -50px;
  align-items: left;
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
  #estra_img{
    margin-top: 0px;
     display: none;
     /* align-items: center;
     justify-content: center;
     height: 250px; */
   }

  
}

/* que es transmetro */

.conformado {
  color: #002856;
  margin-top: 50px;
}

.conformado h2 {
  font-weight: bold;
  color: #00519E;
}

.conformado p {
  
  white-space: normal;
}

.conformado span {
  font-weight: bold;
}
.conformado .col-md-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.conformado .card {
  border: none !important;
}
#sistur {
  padding: 0px;
   }

#queTm_img{
     background-image: url("https://apiwebtm.com/uploads/PSX_20190514_180111_c36eccfaf8.jpg");
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center center;
     /* height: 350px; */
     
     
       }

.cardbox {
  background-color: #fff;
  /* cursor: pointer; */
  margin: 0 5px 50px 0;
  padding: 20px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 20px #707070;
  transition: 0.3s ease-in-out;
  border-radius: 20px;
  /* height: 300px; */
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  
  #organiTM {
    /* display: none; */
  }
  .cardbox {
        margin: 0 5px 50px 0;
    padding: 10px 10px;
  }
}

/* --------------------- */

/* <!-- DATOS DE INFRAESTRUCTURA --> */

.cont-infr h2 {

  color: #00519E;
  font-weight: bold;
  margin-bottom: 30px;
  
}
.linea-colores-peque {
  width: 35%;
  height: 4px;
  /* margin-top: 50px; */
  
  background-image: linear-gradient(
    90deg,
    #e30613 33%,
    #ffdd00 33%,
    #ffdd00 66.66%,
    #00a13a 66.66%
  );
  /*espacio vertical */
  /* margin-bottom: 15px; */
  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
}

.cont-infr img {
  margin-bottom: 10px;
 /*  width: 150px; */
  
}

.cont-infr p {
  color: #004f9f;
}


.cardbox_infra {
  background-color: #fff;
  /* cursor: pointer; */
  margin: 0 50px 50px 0;
  padding: 15px 15px  ;
  width: 250px;
  height: 300px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 20px #707070;
  transition: 0.3s ease-in-out;
  border-radius: 20px;
  /* height: 300px; */
  
}
.cardbox_infra img {
  width: 150px;
}


@media only screen and (min-width: 320px) and (max-width: 768px) {
  .linea-colores-peque {
   /*  width: 35%;
    height: 2px; */
    display: none;
  }
 
  .cardbox_infra {
    margin: 0 auto;
    margin-top: 20px;
    width: 150px;
  height: 240px;
  }
  .cardbox_infra img {
    width: 100px;
  }
  .cont-infr h2 {
    margin-bottom: 0px;
    margin-top: -50px;
  }
 
}
/* ----------------- */
.color-tex-da-infra {
  color: #002856;
  margin-bottom: 50px;
  margin-top: 100px;
}

.color-tex-da-infra h1 {
  
  font-weight: bold;
}



/* <!-- DATOS DE INFRSESTRUCTURA bloque 2 --> */

/* .car__port {
  margin-top: 50px;
  margin-bottom: 50px;
}
.car__port-infra {
  border: 2px solid #004f9f;
  border-radius: 20px;
  padding: 10px;
}
.car__port-infra img {
  width: 60px;
  margin-left: 15px;
}
.car__port-infra h5 {
  color: #002856;
  word-break: keep-all;
  font-weight: bold;
  overflow-wrap: normal !important;

  background-image: linear-gradient(
    transparent 0%,
    transparent 90%,
    rgb(277, 6, 19) 90%,
    rgb(277, 6, 19) 100%
  );
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position: right;
  transition: background-size 300ms;
}
.car__port-infra h5:hover {
  background-size: 100% 100%;
  background-position: left;
}
.car__port-infra a { text-decoration: none; }


@media only screen and (min-width: 320px) and (max-width: 768px) {
  .car__port-infra h3 {
    font-size: 20px;
  }
  .car__port-infra img {
    width: 0px;
  }
  .car__port-infra {
    width: 70vw; 
    margin: 0 auto;
  }
} */
/* --------------------- */

/*DATOS DE INFRAESTRUCTURA bloque 3 */


/* hover */

a i {
  transition: all 0.3s linear;
}

a:hover i {
  transform: scale(1.5);
}

.efecto:hover {
  color: blue;
}

/* card horizontal */

.card- {
  width: auto;
  margin: 20px;
  border-radius: 6px;
  text-align: center;
  background: rgb(1, 4, 46);
  box-shadow: 0px 1px 10px rgba(252, 247, 247, 0.2);
  color: #fff;
  margin-top: 120px;
  margin-right: 20px;
}

.card- img {
  width: 100%;
  height: 218px;
}

/* .card- .contenido-card {
  padding: 15px;
  text-align: center;
} */

.btn-si {
  border: 2px solid white;
  background-color: rgb(1, 4, 46);
}

.btn-no {
  border: 2px solid white;
  background-color: rgb(1, 4, 46);
}

.btn-votar {
  border: 2px solid white;
  background-color: rgb(1, 4, 46);
}

.ver-resultado {
  margin-top: 12px;
}



/* -----Movilizados----------------- */
/* Usuarios Movilizados */

.movilizado {
  box-shadow: 0px 1px 5px 3px rgba(64, 64, 64, 0.37);
  -webkit-box-shadow: 0px 1px 5px 3px rgba(64, 64, 64, 0.37);
  -moz-box-shadow: 0px 1px 5px 3px rgba(64, 64, 64, 0.37);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  transition: all 400ms ease-out;
  cursor: default;
  margin-top: -80px;
  width: 90%;
  background-color: #002856;
  border-radius: 20px;
  width: 50rem;
  
}
.movilizado h2 {
  color: #fff;
  
  /* font-size: 3.5vw; */
}

.movilizado p {
  color: #fff;
}
.movilizado:hover {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3%);
}

.usu-movilizados {
 /*  margin: 18px; */
  background-color: #002856;
  padding: 40px;
  

}
.linea-colores-movi {
  width: 35%;
  height: 4px;
  /* margin-top: 50px; */
  
  background-image: linear-gradient(
    90deg,
    #e30613 33%,
    #ffdd00 33%,
    #ffdd00 66.66%,
    #00a13a 66.66%
  );
  /*espacio vertical */
  /* margin-bottom: 15px; */
  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
}
.movilizado img {
  /* float: right;
  display: flex; */
  width: 40%;
}
.movilizado .card_bus {
  margin-top: -100px;
  display: block;
}
.movilizado .card_bus h1 {
  margin-top: 20px;
  letter-spacing: 3px;
  color: #fff;
}

#videoHome {
 
   margin-right: 0px;
  margin-left: 0px;
  min-width: 100%;
  max-height: 100%;
  /* height: 50rem; */
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .movilizado img {
   
    display: none;
  }
  .movilizado {
    margin-top: -40px;
    /* width: 90%; */
  }
  .movilizado .card_bus {
    margin-top: 10px;
    /* display: inline-block; */
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
  .movilizado .card_bus h1 {
    margin-top: -20px;
    padding-left:25px ;
  }
  .linea-colores-movi {
    margin-bottom: 20px;
    margin-left:25px ;
  }
}


/* Portafolio comercial */

/* acerca de  */

.comercial__card {
  color: #002856;
  margin-top: 50px;
  margin-bottom: 50px;
 
 
}

.comercial__card .card {
  background-color: #F5F5F5;
  
}
.comercial__card h2 {
  margin-bottom: -40px;
  font-weight: bold;
  color: #00519E;
  
}
.comercial__card p {
  padding-right: 30px;
  /* font-style: oblique; */
}

.comercial__card li {
  /* line-height: 4.5; */
  margin-top: 40px;
  color: #e30613;
  
}

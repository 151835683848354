/* ==========================

    Social 

=============================*/
.footer-social-link {
  margin-top: 50px;
  margin-bottom: 50px;
}
.footer-social-link h3 {
  color: #002856;
  /* font-size: 35px; */
  margin-bottom: 25px;
  margin-left: 10px;
  font-weight: bold;
}
.footer-social-link ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 15px;
}
.footer-social-link li {
  display: inline-block;
}
.footer-social-link a {
  text-decoration: none;
}
.footer-social-link a i {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  background: #000;
  margin-right: 5px;
  color: #fff;
}
.footer-social-link a i.fa-facebook {
  background-color: #3b5998;
}
.footer-social-link a i.fa-twitter {
  background-color: #55acee;
}
.footer-social-link a i.fa-youtube {
  background-color: #b7242a;
}
.footer-social-link a i.fa-instagram {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

/* ==========================

    Contact

=============================*/
/* .contact__us {
  position: relative;
  z-index: 2;
  margin-top: 65px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
} */

/* .contact__us-info {
  margin-left: 10px;
  color: #002856;
  position: relative;
}
.contact__us-info h5 {
 
  color: #002856;
  position: relative;
  margin-bottom: 15px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: bold;
}
.contact__us-info span {
  color: #002856;
  position: relative;
  padding: 0;
}
.contact__us-info p {
  
  color: #002856;
  position: relative;
  margin-top: 25px;
  padding: 0;
  font-weight: bold;
}
.contact__us-info ul {
  font-size: 15px;
  color: #002856;
 
  list-style: none;
  margin: 0;
  padding: 0;
}

.contact__us-info li {
  
  color: #c00d0d;
  font-weight: bold;
}
.contact__us-info i {
  color: #c00d0d;
  font-size: 20px;
}
.contact__us-info a {
  text-decoration: none;
  color: inherit;
  color: #002856;
} */
/* 
.cont__us h3 {
  
  color: #002856;
  position: relative;
  margin-bottom: 15px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 10px;
  font-weight: bold;
}

.cont__us li {
 
  margin-top: 15px;
  color: #002856;
}
.cont__us i {
  color: #c00d0d;
  margin-top: 5px;
  font-size: 20px;
}
.cont__us a {
  text-decoration: none;
  color: inherit;
  color: #002856;
}
.cont__us span {
  font-size: 18px;
 
} */
/* ==========================

    Google Maps Footer

=============================*/
/* .gmap {
  width: 17rem;
  margin-left: 15px;
  margin-top: 20px;
  position: relative;
  border-radius: 10px;
  border: 3px solid #004f9f;
}

.gmap li {
  color: #002856;
}
.gmap i {
  color: #c00d0d;
  margin-top: 15px;
  font-size: 20px;
} */

/* ==========================

    Copyright 

=============================*/

/* .copyright {
  padding: 28px 0;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
}
.copyright span {
  color: #002856;
  font-size: 18px;
  margin-left: 5px;
  font-weight: bold;
}
.copyright p {
  color: #002856;
  margin-top: 25px;
  margin-left: 10px;
}

.copyright__img-gov {
  margin-top: 5px;
}
.copyright__img-baq {
  margin-top: 5px;
}
.copyright__img-min {
  margin-top: 5px;
}
.copyright__img-co {
  margin-top: 5px;
}
.copyright__img-amb {
  margin-top: 5px;
}
.copyright__img {
  width: 150px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .copyright__img-gov {
    width: 25vw !important;
  }
  .copyright__img-baq {
    width: 25vw !important;
  }
  .copyright__img-min {
    width: 25vw !important;
  }
  .copyright__img-co {
    width: 10vw !important;
  }
  .copyright__img-amb {
    width: 20vw !important;
  }
  .copyright__img {
    width: 30vw;
  }
} */
/* -------- rediseño--- */
/* ==========================

    Contact

=============================*/

.contact__us{
  color: #fff;
  margin-left: 50px;
  margin-top: 70px;
  margin-bottom: 80px;
}

.contact__us ul {
  list-style: none;
  padding: 0;
}
.contact__us i {
  margin-right: 5px;
  color: #FF0000;
}
.contact__us span {
  font-weight: bold;
}

.contact__us h5 {
  margin-bottom: 13px;
}
.contact__us p {
 font-size: 14px !important;
}

.preguntas {
margin-top:34px;
display: flex;

}
.preguntas li {
  font-size: 0.875rem;
  padding-left: 10px;
}
.preguntas li:nth-child(1){
  padding-left: 0px;
}
.preguntas a {
  text-decoration: none;
  color: #fff;
}
#cardBlue{
  height: 544px;
}
/* ==========================

    Google Maps Footer

=============================*/

.google__Maps {
  max-width: 330px; 
  /* height: 150px; */
  margin-top: 130px;
  margin-left: -5rem;
  margin-right: 1rem ;
}

.google__Maps iframe {
  max-width: 330px; 
  height: 250px; 
}

/* ==========================

    Entes Vigilancia Footer

=============================*/
/* .gobiIcon ul {
  align-items: center;
  justify-content: center
}

.gobiIcon li {
  color: #0D4F9E;
  margin-left: .8rem;
  border: 1px solid #fff;
} */

.gobiIcon {
  margin-top: 20px;
  margin-bottom:  20px;
}
.copyright {
  /* align-items: center;
  justify-content: center; */
  
  color: #0D4F9E;
  margin-top: 10px;
  
  margin-left: 50px;
}
.copyright span {
  font-weight:bold;
}

.gobiIcon_Pres  {
width: 8rem;
margin: 0 auto;
display: block;

}
.gobiIcon_Alcal  {
  width: 8rem;
  /* margin-top: -7px; */
  margin: 0 auto;
  display: block;
}
.gobiIcon_MinTrans  {
  width: 9rem;
  margin-top: 1px;
  margin: 0 auto;
  display: block;
}
.gobiIcon_MinComer  {
  width: 2.3rem;
  margin-top: -1px;
  margin: 0 auto;
  display: block;
}
.gobiIcon_AreaMetro {
  width: 4rem;
  margin-top: -1px;
  margin: 0 auto;
  display: block;
} 

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .gobiIcon_Pres  {
    width: 8rem;
      
    }
    .gobiIcon_Alcal  {
      width: 8rem;
    
    }
    .gobiIcon_MinTrans  {
      width: 9rem;
      
    }
    .gobiIcon_MinComer  {
      width: 2.3rem;
    }
    .gobiIcon_AreaMetro {
      width: 4rem;
     
    } 
}

/* ==========================

    @Media Footer

=============================*/
@media (max-width: 1024px) {
   .google__Maps {
    width: 30vw;
  height: 90%;
  
  }
  .preguntas li {

padding-left: 2px;
  }

}
@media (max-width: 992px) {
  .contact__us {
    margin-left: 0px;
  }
  .contact__us h5 {
    text-align: center;
    
  }
  .contact__us li {
    text-align: center;
  }
  .contact__us p {
  margin-left: 7.5vw;
  
  
  }
  .preguntas {
    padding-left: 1px;
    
  }
  /* .preguntas li {
    padding-left:-17px;
  } */
  #gridBlanco {
    height:350px;
  }
 
 
    .google__Maps {
     width: 28vw;
   height: 80%;
   margin-left: -2.8rem;
   
   
 }

}
@media (max-width: 767px) {
  .google__Maps {
  width: 50vw;
  height: 80%;
  margin-top: -100px;
  margin-left: 23.1vw;
  }
  .contact__us p {
    display: none;
        
    }
  
    .preguntas {
      flex-direction: column;
        
     }
   
  #cardBlue{
    height: 1175px;
  }

}
@media (max-width: 468px){

  .google__Maps {
    width: 90vw;
  height: 80%;
  margin-top: -100px;
  margin-left: 2vw;
  }
}
/* ------ */
.container-grid { 
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 3fr;
  grid-template-rows: 0.5fr;
  grid-auto-rows: 1fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  
    margin-top: 25px;
    margin-bottom: 25px;
    /* align-items: center; */
}



.rojo-p {
  background-color: #e30613;
}

.rojo-lt {
  color: #e30613;
}

.azul-p {
  background-color: #004f9f;
}

.azul-lt {
  color: #00519E;
}

.amarillo-uni {
  background-color: #ffdd00;
}

.verde-uni {
  background-color: #00a13a;
}
.car__transb {
  margin: 0 auto;
}
.car__transb h2 {
  color: #00519E;
  margin-top: 60px;
  font-weight: bold;
}
.car__transb p {
  color: #004f9f;
  font-weight: bold;
  margin-top: 50px;
  /* font-size: 18px; */
}
.car__transb span {
  color: #e30613;
}
.matriz_transbordo .tabs h1 {
  margin: 0;
  line-height: 2;
  text-align: center;
}
.matriz_transbordo .tabs h2 {
  margin: 0 0 0.5em;
  font-weight: normal;
}
.matriz_transbordo .tabs input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.matriz_transbordo .tabs .row {
  display: flex;
}
.matriz_transbordo .tabs .row .col {
  flex: 1;
}
.matriz_transbordo .tabs .row .col:last-child {
  margin-left: 1em;
}
/* Accordion styles */
.matriz_transbordo .tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.144);
  border: 3px solid #c00d0d;
  border-radius: 15px;
  width: 100%;
}
.matriz_transbordo .tabs .tab {
  width: 100%;
  color: #e30613;
  overflow: hidden;
}
.matriz_transbordo .tabs .tab-label {
  display: flex;
  justify-content: space-between;

  background: #ffffff;
  font-weight: bold;
  cursor: pointer;
  border-radius: 15px;

  padding: 15px;

  font-size: 18px;

  border-bottom: 1px solid #fff;

  /* Icon */
}

.matriz_transbordo .tabs .tab-label:hover {
  background: #ffdd00;
}
.matriz_transbordo .tabs .tab-label::after {
  content: '\276F';
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.matriz_transbordo .tabs .tab-content {
  max-height: 0;

  background: white;
  transition: all 0.35s;
}
.matriz_transbordo .tabs .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #ffffff;
  cursor: pointer;
}
.matriz_transbordo .tabs .tab-close:hover {
  background: #ffdd00;
}
.matriz_transbordo .tabs input:checked + .tab-label {
  background: #ffdd00;
}
.matriz_transbordo .tabs input:checked + .tab-label::after {
  transform: rotate(90deg);
}
.matriz_transbordo .tabs input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

.sin-border {
  border: none;
}

.titulo-plus {
  /* margin: 4%; */
  margin-top: 50px;
  color: #00519E;
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  .matriz_transbordo .tabs .tab-content {
    font-size: 13px;
  }
}

/* ---------- */
/* como funciona */

.funciona-tm {
  border: 3px solid #c00d0d;
  border-radius: 30px;
  color: #002856;
  
}
.funciona-tm .card {
  display: flex;
  align-items: center;
  justify-content: center
}
.funciona-tm p {
  padding: 1px;
  color: #004f9f;
  font-size: 18px;
  text-align: center;
}
.funciona-tm img {
  width: 15%;
  margin-top: 25px;
 
  
}
.funciona-tm-img {
  width: 20% !important;
  padding-top: 25px;
 /*  margin-bottom: 10px; */
 
}

@media only screen and (min-width: 250px) and (max-width: 1320px) {
  .funciona-tm img {
    width: 25%;
  }
  .funciona-tm-img {
    width: 30% !important;
    padding-top: 1px;
    margin-bottom: 5px;
  }

  .car__transb p {
    text-align: center;
  }
}
/* ten en cuenta */

.encuenta-tm {
  border: 3px solid #e30613;
  border-radius: 15px;
  max-width: 100%;
  margin: auto;
  text-align: auto;
}
.encuenta-tm h2 {
  color: #00519E;
  font-weight: bold;
}
.encuenta-tm img {
  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.encuenta-tm p {
  /* margin-left: 1%; */
  color: #002856;
  /* font-size: 18px; */
  /* margin: auto; */
    display: block;
}

.encuenta-tm i {
  margin: 3%;
}

.encuenta-tm span {
  /* margin: 1%; */
  color: #ffdd00;
  font-weight: bold;
  font-size: 3.5em;
  /* margin: auto; */
    display: block;
}
.encuentra-tm-li {
  border: 3px solid #e30613;
  border-left: none;
  border-right: none;
}

@media only screen and (min-width: 250px) and (max-width: 768px) {
  .encuenta-tm h2 {
    margin-left: 1rem;
    color:#00519E;
  }

  .encuenta-tm span {
    text-align: center;
    margin: auto;
    display: block;
  }

}
.reloj {
  margin-top: 10%;
  margin-right: 30%;
  margin: 0 auto;
  text-align: auto;
  margin-bottom: 23px;
}

.reloj-icono {
  position: relative;
  z-index: 2;
  margin-top: -34px;
}

.time-tm {
  border: 3px solid #c00d0d;
  border-radius: 23px;
  /* z-index: 1; */
}

.reloj h2 {
  /* font-size: 2rem; */
  font-weight: bold;
  color: #00519E
}
.reloj p {
  /* font-weight: bold; */
  font-size: 18px;
  text-align: center;
}
.reloj .row {
  margin-top: 112px;
}
/* -------------------------------- */

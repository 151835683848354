.directorio_Fun {
  height: 100%;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  display: block;
  align-items: center;
}

.directorio_Fun h2 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #00519E;
  padding: 5%;
  margin: 0 auto;
}

.directorio_Fun h5 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #e30613;
  text-align: center;
  margin: 0 auto;
}
.directorio_Fun th:hover {
  background-size: 100% 100%;
  background-position: left;
}
.directorio_Fun p {
  text-align: center;
  color: #002856;
  margin: 0 auto;
}
.directorio_Fun a {
  text-decoration: none;
}

.directorio_Fun th {
  margin: 0 auto;
  background-image: linear-gradient(
    transparent 0%,
    transparent 90%,
    rgb(277, 6, 19) 90%,
    rgb(277, 6, 19) 100%
  );
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position: right;
  transition: background-size 300ms;
}

/* .containerr h5 {
  font-weight: bold;
  color: #002856 !important;
  margin-top: 40px;
  margin-left: 40px;

  background-image: linear-gradient(
    transparent 0%,
    transparent 90%,
    rgb(277, 6, 19) 90%,
    rgb(277, 6, 19) 100%
  );
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position: right;
  transition: background-size 300ms;
}
.containerr h5:hover {
  background-size: 100% 100%;
  background-position: left;
}

.containerr a {
  text-decoration: none;
  font-weight: bold;
  color: #002856 !important;
} */

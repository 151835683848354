.azul-lt {
  color: #00519E;
}

#accesibilidad span {
  font-weight: bold;
}
#accesibilidad h2 {
  font-weight: bold;
  color: #00519E;
}

.accesibilidad-tm {
  margin-top: 50px;
  margin-bottom: 50px;
}

.accesibilidad-tm i {
  padding: 5%;
  padding-top: 40%;
  color: #c00d0d;

  margin: 0 auto;
}
.accesibilidad-tm  p {
  color: #002856;
  
}
.accesibilidad-tm h5 {
  font-weight: bold;
}

.accesibilidad-tm .accesibilidad-border {
  border: 3px solid #00a13a;
  border-radius: 20px;
}

.accesibilidad-tm a {
  text-decoration: none;
  color: #002856;
  font-weight: bold;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .accesibilidad-tm .accesibilidad-border {
    width: 280px;
  }
  .accesibilidad-tm i {
    padding: 1%;
    padding-top: 10%;
    
  
   
  }
}

/* accordeon */

span.tecla {
  color: #b70000;
  border: 2px solid;
  border-color: #b70000;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 10px;
  line-height: 42px;
}

.rojo-p {
  background-color: #e30613;
}

.rojo-lt {
  color: #e30613;
}

.azul-p {
  background-color: #004f9f;
}

.azul-lt {
  color: #002856;
}

.amarillo-uni {
  background-color: #ffdd00;
}

.verde-uni {
  background-color: #00a13a;
}

.rutali__cards {
  margin-top: 50px;
  margin-bottom: 100px;
}
.rutali__cards p {
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.8;
  color: #002856;
  margin-bottom: 5px;
}
.tabs h1 {
  margin: 0;
  line-height: 2;
  text-align: center;
}
.tabs h2 {
  margin: 0 0 0.5em;
  font-weight: normal;
}
.tabs input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tabs .row {
  display: flex;
}
.tabs .row .col {
  flex: 1;
}
.tabs .row .col:last-child {
  margin-left: 1em;
}
/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.144);
  border: 3px solid #c00d0d;
  border-radius: 15px;
  width: 70vw;
  margin-top: 50px;
}
.tabs .tab {
  color: #e30613;
  overflow: hidden;
}
.tabs .tab-label {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  font-weight: bold;
  cursor: pointer;
  border-radius: 15px;
  padding: 15px;
  font-size: 20px;
  border-bottom: 1px solid #fff;

  /* Icon */
}

.tabs .tab-label:hover {
  background: #ffdd00;
}
.tabs .tab-label::after {
  content: '\276F';
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tabs .tab-content1 {
  /* max-height: auto !important; */
  padding: 0 1em;

  background: white;
  transition: all 0.35s;
}

.tabs .tab-content-color:hover {
  background-color: #ffdd00;
}
.tab-content-color:hover {
  background-color: #ffdd00;
}
.tabs .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #ffffff;
  cursor: pointer;
}
.tabs .tab-close:hover {
  background: #ffdd00;
}
.tabs input:checked + .tab-label {
  background: #ffdd00;
}
.tabs input:checked + .tab-label::after {
  transform: rotate(90deg);
}
/* .tabs input:checked ~ .tab-content {
  min-height: 100vh;
  padding: 1em;
} */

.sin-border {
  border: none;
}

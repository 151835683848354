.tarjetas__tm {
    margin-bottom: 50px;
    background-color: #F5F5F5;
}
.tarjetas__tm h2 {
color: #002856;
margin: 0 50px 25px 0;
font-weight: bold;
}

.tarjetas__tm .card-body {
 /* margin: 0 auto; */
}

.tarjetas__tm p {
color: #002856;   
font-style: oblique; 
 

 }
.tarjetas__tm .card {
    background-color: #F5F5F5;
 }

.tarjetas__tm .card img {
    border-radius: 20px;
    margin-bottom: 50px;
     }


.cardMini {
  margin-bottom: 50px;
}
.cardMini .card {
 border-radius: 20px;
 
 box-shadow: 0 5px 20px rgba(0,0,0,0.5);
 transition: 0.3s ease-in-out;

 }
.cardMini .card img {
  border-radius: 20px;

 }

.cardMini .card p {
  color: #002856;   
  font-style: oblique; 
 
 }

 @media only screen and (min-width: 320px) and (max-width: 768px) {
  .cardMini {
    width: 80%;
  }

 /*  .tarjetas__tm .card img {
    width: 80%;
     } */
}
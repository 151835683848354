/* .card__Estaciones h1 { 
    color: #e30613;
    font-weight: bold;
    margin-bottom: -50px;
 }
 */


.estaciones .activeTab{
color: #fff;
/* border: 1px solid #002856; */
/* font-weight: bold;
font-size: 14px; */
background-color: #002856;
}

.estaciones .baseTab {
    border: 1px solid #002856;
    font-weight: bold;
    font-size: 14px;
   
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
.estaciones .collapse li {
    font-size: 12px;
}
}
/* Horarios */
.card__horario {
    margin-left: -12px;
    margin-top: 50px;
    
  }
  
  .card__horario h2 {
    color: #002856;
    font-weight: bold;
    margin-bottom: -20px;
  }
  .card__horario .baseTab{
   
    background-color: #F5F5F5;
    cursor: pointer;
    width: 100px;
    height: 50px;
    margin: 50px 0 10px 0;
    /* padding: 20px 15px; */
    display: flex;
    align-items: center;
    justify-content: center;
   /*  box-shadow: 0 5px 20px rgba(0,0,0,0.5);
    transition: 0.3s ease-in-out; */
    border-radius: 1px;
   font-weight: bold;
   color:#002856;
   font-size: 18px;
  }
  
  .card__horario .baseTab:hover{
    background-color: #002856 !important;
    color: white !important;
  }
  
  
  
  .card__horario .activeTab{
    background-color: #002856 !important;
    color: white !important;
    
  }
  
  .card__horario p{
    color:#002856;
  }
  .card__horario span{
    font-weight: bold;
  }
  
  .card__horario #horario_img {
    background-image: url("https://apiwebtm.com/uploads/tm21_ff23f112d8.PNG");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
  }

  @media only screen and (min-width: 320px) and (max-width: 768px) {
    .card__horario .baseTab{ 
      margin: 30px 0 10px 0;
    }
    .card__horario .col-md-6 .card-body { 
      margin-left: 20px !important ;
    }
  }
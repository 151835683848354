/* Nav inferior */
.container__wave {
    background-color: #e30613;
    /* height: 505px; */
    margin-top: -11px;
  }
  
  .wave__card-group {
    
    margin: 79px 0 79px;
    /* display: block; */
  }
  
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.3fr 0.3fr;
    gap: 10px 10px;
    grid-auto-flow: row;
    grid-template-areas:
      ". ."
      ". .";
    background-color: #e30613;
    width: 450px;
    /* margin-left: 200px; */
     
  }
  
  .wave__card {
    
    border-radius: 10px;
    background-color: #e30613;
    border: 3px solid #fff !important;
    color: #fff;
    /* Altura */
    max-height: 140px !important;
    
  }
  .wave__card:hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
    transform: translateY(-3%);
    background-color: #004f9f;
    color: #fff;
  }
  .wave__card a {
    color: #fff;
    text-decoration: none;
  }
  .wave__card a:hover {
    color: #fff;
    
  }
  .wave__card img {
    width: 70px;
    
    }
  
  .wave__card img:hover {
    background-color: #004f9f;
    }
  
    @media only screen and (min-width: 320px) and (max-width: 400px) {
      .wave__card .card-title {
        font-size: 15px;
      }
    }
  
    
    @media only screen and (max-width: 992px) {
   /*    .r2v04k_mv-widget-wrapper iframe.responsive {
        height: 45vw;
        max-height: 45vw !important;
      } */
      .r2v04k_mv-widget-wrapper {
        width: 100% !important;
      }
    
      .wave__card-group {
        width: 40vw !important;
        /* margin: 0 auto;
        display: block; */
        margin-top: 70px;
      }
      
      .wrapper {
        width: 47vw;
        margin-left: -70px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 768px) {
      .wave__card-group {
        width: 80vw !important;
        margin: 0 auto;
        display: block;
        margin-top: 50px;
      }
      
      .wrapper {
        width: 81vw;
        margin-left: 0px !important;
      }
     
      .moovit__card {
        /* margin: 0 auto;
        display: block; */
        /* margin-top: 100px;
        width: 100% !important; */
        margin: 0 auto !important;
        width: 80vw !important;
        margin-top: 50px !important;
        margin-bottom: 50px !important;
      }
     

    }

    /* Moovit */
    
.moovit__card {
   /*  margin-top: 20px;
    margin-bottom: 20px; */
    margin: 0px 0 0 8rem;
    border-radius: 10px;
    background-color: #e30613;
    border: 3px solid #fff;
    max-width: 350px;
    /* height: 70vh; */
    /* display: block; */
  }
  moovit-preview .widget-container {
    display: table-cell;
    vertical-align: top;
  }
  moovit-preview .widget-container {
    text-align: center;
    width: 66%;
  }
  html[dir='ltr'] moovit-preview .widget-container {
    padding-left: 40px;
  }
  moovit-preview .iframe-container {
    display: block;
    text-align: center;
  }
  moovit-preview .iframe-container.responsive {
    height: 400px;
  }
  * {
    box-sizing: border-box;
  }
  a {
    -webkit-transition: color 0.3s, background 0.3s;
    transition: color 0.3s, background 0.3s;
  }
  a {
    color: #2196f3;
    cursor: pointer;
    font-size: 14px;
  }
  a:hover {
    color: #0d8aee;
  }
  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f5f7f7;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #bdc3c7;
  }
  /*! CSS Used from: Embedded */
  .r2v04k_mv-widget-wrapper iframe {
    margin-bottom: 0;
  }
  .r2v04k_mv-widget-wrapper iframe.responsive {
    display: block;
    /* height: calc(100% - 142px); */
    height: 200px;
    /* max-height: 25vw !important; */
  }
  .r2v04k_mv-widget-wrapper {
    background: #fff;
    border: 1px solid #ddd;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    overflow: hidden;
  }
  .r2v04k_mv-widget-wrapper.rounded-corners {
    border-radius: 8px;
  }
  .r2v04k_mv-widget-title {
    background: #fff;
    font-size: 1.17em;
    margin: 0;
    padding: 18px 22px 2px;
    text-align: center;
  }
  .r2v04k_mv-widget-title a {
    color: #292a30 !important;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }
  .r2v04k_mv-widget-title a:focus {
    outline: 1px solid #e1e3e5;
    outline-offset: 2px;
  }
  .r2v04k_mv-widget-title a:hover {
    color: #292a30 !important;
    text-decoration: underline;
  }
  .r2v04k_mv-widget-footer {
    background: #fff;
  }
  .r2v04k_mv-widget-lines-around {
    padding: 7px 24px 7px;
    text-align: initial;
  }
  .r2v04k_mv-widget-lines-around a {
    color: #292a30;
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.3;
    text-decoration: none;
    vertical-align: middle;
  }
  .r2v04k_mv-widget-lines-around a::after {
    background: url(https://widgets.moovit.com/w/images/arrow_black.svg) no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 7px;
    margin: 0 4px;
    width: 7px;
  }
  .r2v04k_mv-widget-lines-around a:hover {
    text-decoration: underline;
  }
  .r2v04k_mv-widget-lines-around a:focus {
    outline: 1px solid #e1e3e5;
    outline-offset: 2px;
  }
  .r2v04k_mv-widget-footer-links {
    border-top: 1px solid #ddd;
    box-sizing: border-box;
    display: table;
    padding: 10px 24px 12px;
    width: 100%;
  }
  .r2v04k_mv-widget-footer-links a:focus {
    outline: 1px solid #e1e3e5;
    outline-offset: 2px;
  }
  .r2v04k_mv-widget-get-link,
  .r2v04k_mv-widget-moovitHomePageLink {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
  }
  .r2v04k_mv-widget-moovitHomePageLink {
    text-align: left;
  }
  .r2v04k_mv-widget-moovitHomePageLink img {
    height: 20px;
    vertical-align: top;
    width: 60px;
  }
  .r2v04k_mv-widget-get-link {
    color: #7a7c7f;
    font-size: 12px;
    text-align: right;
    text-decoration: underline;
    transition: color 0.3s;
  }
  .r2v04k_mv-widget-get-link:hover {
    color: #292a30;
  }
  .black_blue {
    background: #2b2b31;
  }
  .widget-container .responsive .mv-gd-widget-20 {
    height: 100%;
  }
  /*! CSS Used from: Embedded */
  .r2v04k_mv-widget-title {
    background: #292a30;
  }
  .r2v04k_mv-widget-title {
    color: #fff !important;
    font-weight: bold;
  }
  
  .r2v04k_mv-widget-footer {
    background: #292a30;
  }
  .r2v04k_mv-widget-footer-links {
    border-top: 1px solid #525357;
  }
  .r2v04k_mv-widget-lines-around a {
    color: #fff !important;
  }
  .r2v04k_mv-widget-lines-around a:hover {
    text-decoration: underline;
    color: #fff !important;
  }
  .r2v04k_mv-widget-lines-around a::after {
    background: url(https://widgets.moovit.com/w/images/arrow_white.svg) no-repeat;
  }
  .r2v04k_mv-widget-get-link {
    color: #bdc3c7 !important;
  }
  .r2v04k_mv-widget-get-link:hover {
    color: #fff !important;
  }

 
  /* 
  @media only screen and (min-width: 768px) and (max-width: 1036px) {
    .r2v04k_mv-widget-wrapper iframe.responsive {
      height: 35vw;
      max-height: 35vw !important;
    }
  
    .wave__card-group {
      width: 100% !important;
      padding-left: 30px;
    }
    .wave__card {
      max-width: 180px;
    }
    .r2v04k_mv-widget-wrapper {
      width: 100% !important;
    }
    .moovit__card {
      padding-left: 0px;
    }
  } */
@import url('https://fonts.googleapis.com/css2?family=Baloo+2&display=swap');
/* This pen */
body {
  /* font-family: 'Baloo 2', cursive; */
  font-size: 16px;
  /*  color: #ffffff; */
  text-rendering: optimizeLegibility;
  font-weight: initial;
}

.light {
  background: #f3f5f7;
}
/* title noticias */
.card__noti {
  margin-top: 25px;
}
.card__noti h2 {
  font-weight: bold;
  color: #00519E;
}
.card__noti img {
  width: 300px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
.card__noti img {
  width: 60vw;
}
}

/* Cards */
.nt {
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 80px 0 20px 0;
  overflow: hidden;
  position: relative;
}

.nt.light {
  background-color: #e1e5ea;
}

.nt a {
  color: inherit;
}
.nt h1,
.nt .h1 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.nt__title {
 
  color: #002856;
}
.nt__img {
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  position: relative;
}
.nt__img_link {
  display: contents;
}
.nt__bar {
  width: 50px;
  height: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #424242;
  transition: width 0.2s ease;
}
.nt__text {
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.nt__text a {
  color: #002856;
}
.nt__preview-txt {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  height: 100%;
  color: #00285d;
}

.nt:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-70deg, #424242, transparent 50%);
  opacity: 1;
  border-radius: 10px;
}
.nt:hover .nt__bar {
  width: 100px;
}
@media screen and (min-width: 769px) {
  .nt {
    flex-wrap: inherit;
  }
  .nt .nt__title {
    /* font-size: 2rem; */
  }
  .nt .nt__tagbox {
    justify-content: start;
  }
  .nt .nt__img {
    max-width: 300px;
    max-height: 100%;
    transition: transform 0.3s ease;
  }
  .nt .nt__text {
    padding: 3rem;
    width: 100%;
  }

  .nt:hover .nt__img {
    transform: scale(1.1);
  }
  .nt:nth-child(2n + 1) {
    flex-direction: row;
  }
  .nt:nth-child(2n + 0) {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 1024px) {
  .nt__text {
    padding: 2rem 3.5rem;
  }

  .nt.light .nt__text:before {
    background: #e1e5ea;
  }
}
/* COLORS */

.blue .nt__title:hover {
  color: #0076bd;
}
.blue .nt__bar {
  background-color: #0076bd;
}
.blue::before {
  background-image: linear-gradient(
    -30deg,
    rgba(0, 118, 189, 0.1),
    transparent 50%
  );
}
.blue:nth-child(2n)::before {
  background-image: linear-gradient(
    30deg,
    rgba(0, 118, 189, 0.1),
    transparent 50%
  );
}

.red .nt__title:hover {
  color: #bd150b;
}
.red .nt__bar {
  background-color: #bd150b;
}
.red::before {
  background-image: linear-gradient(
    -30deg,
    rgba(189, 21, 11, 0.1),
    transparent 50%
  );
}
.red:nth-child(2n)::before {
  background-image: linear-gradient(
    30deg,
    rgba(189, 21, 11, 0.1),
    transparent 50%
  );
}

@media screen and (min-width: 769px) {
  .blue::before {
    background-image: linear-gradient(
      -80deg,
      rgba(0, 118, 189, 0.1),
      transparent 50%
    );
  }
  .blue:nth-child(2n)::before {
    background-image: linear-gradient(
      80deg,
      rgba(0, 118, 189, 0.1),
      transparent 50%
    );
  }
  .red::before {
    background-image: linear-gradient(
      -80deg,
      rgba(189, 21, 11, 0.1),
      transparent 50%
    );
  }
  .red:nth-child(2n)::before {
    background-image: linear-gradient(
      80deg,
      rgba(189, 21, 11, 0.1),
      transparent 50%
    );
  }
}

.navPage {
  /*  padding-left: 400px; */
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.navPage button {
  margin-left: 20px;
  color: #002856;
}

/* GALERIA */
.contianer__galeria {
  border-radius: 20px 20px;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  overflow: hidden;
  width: 90%;
  border: 3px solid #002856;
}



.contianer__galeria h2 {
  text-align: center;
  color: #002856;
  /* font-size: 2rem; */
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bold;
}

.card__galeria .carousel-item img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  
}
@media only screen and (min-width: 320px) and (max-width: 810px) { 
  .card__galeria .carousel-item img {
    height: 250px;
       
  }
}
/* Galeria Videos */
.card__informativos {
  margin-bottom: 50px;
  margin-top: 50px;
}

.card__informativos .card {
  
   /* height:315px;  */

   overflow: hidden;
   background: #fff;
   box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
   transition: all 400ms ease-out;
   cursor: default;
}

.card__informativos .card:hover {
  
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
    transform: translateY(-3%);
  
}

.card__informativos iframe {
  /* width:560px; */
   height:315px;  
}

.card__informativos h2 {
  text-align: center;
  color: #00519E;
  /* font-size: 2rem; */
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: bold;
}

@media only screen and (min-width: 320px) and (max-width: 810px) {

  .card__informativos iframe {
    width: 100%;
    height: 100%;
  }
}


/* Rediseño Galeria */

/* acerca de  */

.card_gallery {
   margin-top: 50px;
}

.card_gallery .card {
  background-color: #F5F5F5;
}
.card_gallery h2 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #00519E;
  
}
.card_gallery p {
  padding-right: 30px;
  font-style: oblique;
}

.accordion-total {
  margin-top: 10%;
  margin-bottom: 10%;
  border-radius: 10px;
}
.accordion-total a {
  color: black;
  text-decoration: none;
}

.accordion-header:hover {
  background-color: #ffdd00;
}

.hv .bor:hover {
  background-color: #ffdd00;
  opacity: 0.7;
}

.bor {
  height: 50px;
  border-color: #fff;
  padding: 12px;
  background-color: #fff;
  border-radius: 5px;
}
.bor a {
  color: #002856;
  font-weight: bold;
}
.accordion-header h5 {
  color: #002856;
  font-weight: bold;
}

.accordion-body p {
  color: #002856;
  font-size: 18px;
}
.accordion-button:focus {
  background: #ffdd00;

  outline: none;
  box-shadow: 5px 5px 5px #888888;
}

/* .accordion-flush {
  background-color: #ffdd00;
}
 */
 .transparencia {
  margin-bottom: 50px;
 }
 .transparencia .accordion-item {
  
  border-color: #00519E;
  
  background-color: #fff;
  border-radius: 5px;
 }
 .transparencia button {
  color: #00519E;
  font-weight: bold;
  
}
.transparencia a {
  color: #00519E;
  
}
.transparencia li {
  color: #00519E;
  font-size: 14px;
}


 #transparencia {
  margin-top: 50px;
 }
.transp h2 {
  color: #00519E;
  font-weight: bold;
  /* font-size: 60px; */
  
}

.transp h3 {
  
  color: #e30613;
  /* font-size: 40px; */
  margin-top: 10px;
  margin-bottom: 40px;
}

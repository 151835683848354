.newsview {
  margin-top: 65px;
  display: absolute;
}

.backNoti {
  margin-top: 20px;
  margin-bottom: 50px;
}

.newsviewimg {
  background-color: darkgray;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
}
.newsviewdetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.newsviewtitlesection {
  margin-bottom: 20px;
  
}

.newsviewtitle h1 {
  margin-bottom: 6px;
  margin-top: 30px;
  padding: 1%;
  color: #002856;
  font-weight: bold;
}

.newsviewbody {
  font-size: large;
  color: #002856;
}

.newsview img {
border-radius: 20px;
width: 70vw;
margin: 0 auto;
}
/* .newsviewbody::first-letter {
  font-weight: 700;
  font-size: 4em;
  line-height: 0.83;
  float: left;
  margin-right: 7px;
  margin-bottom: 4px;
  color: #c00d0d;
} */

.newsviewbody {
  clear: left;
  font-size: 18px;
  line-height: 1.58;
  letter-spacing: -0.003em;
  padding: 0 20%;
}

@media only screen and (min-width: 320px) and (max-width: 768px) { 
  .newsviewbody {
    padding: 0 10%;
  }
  .newsview img {
    
    width: 80vw;
    
    }
}
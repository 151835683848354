/*BLOQUE 2*/

.forma {
  background-color: rgb(199, 7, 7);
  height: 200px;
  /*display: flex;*/
  align-items: center;
}

.formas1 {
  background-color: rgb(199, 7, 7);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 20px;
  width: 431px;
  height: 110px;
  position: absolute;

  margin: 0 35.3px 0 0;
}

.formas2 {
  background-color: rgb(199, 7, 7);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 20px;
  width: 326px;
  height: 110px;
  position: absolute;
  margin: 0 38.6px 0 0;
}
.formas3 {
  background-color: rgb(199, 7, 7);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 20px;
  width: 156px;
  height: 110px;
  position: absolute;
  overflow: hidden;
}

.txt-r {
  font-size: 13px;
}
.txt-ra {
  font-size: 13px;
  text-align: right;
}
.txt-trans {
  font-size: 13px;
  text-align: right;
  /* justify-content:flex-end;*/
}

.formas3:hover {
  background-color: blue;
}

.hov::before {
  height: 180%;
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;

  z-index: -1;
  transition: 455s;

  border-radius: 50% 50% 0 0;
}

.formas4 {
  background-color: rgb(19, 3, 105);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 20px 3px 0px 20px;
  width: 260px;
  height: 368px;
  position: relative;
}

/*FIN BLOQUE 2*/

hr {
  height: 10px;
  background-color: rgb(21, 2, 128);
}

.borTerm {
  border: 2px solid #002856;
  border-color: #002856;
  border-radius: 20px;
  height: 70px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
}

.borTerm h5 {
  color: #002856;
  /* font: 20px;
  font-size-adjust: 0.58; */
  font-size: 3vw;
  font-weight: bold;
}

.borTerm1 {
  border: 2px solid #002856;
  border-color: #002856;
  height: 120px;
  border-top: none;
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* word-break: break-all; */
  color: #002856;
}
.borTerm1 h3 {
  color: #002856;
  font: 20px;
  font-size-adjust: 0.58;
  padding-top: 10px;
  /* margin-bottom: -30px; */
}

/*Mapa /*/
.mapa {
  border: 2px solid #002856;
  border-color: #002856;
  border-radius: 0 20px 20px 20px;
  margin-bottom: 50px;
}

.map {
  background-color: #002856;
  width: 40%;
  border: 2px solid #002856;
  border-color: #002856;
  border-radius: 0 0 20px 20px;
  height: 70px;

  margin-left: -14px;

  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
}
.map h4 {
  color: white;
  /*  font: 20px;
  font-size-adjust: 0.58; */
  font-size: 3vw;
}
.img-mapa {
  width: 59%;
  height: 560%;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .map {
    /* width: 50%; */
    border-radius: 0 0 20px 20px;
    height: 40px;
    border-left: none;
    border-top: none;
  }
  .map h4 {
    padding-top: 5px;
  } /*
  .borTerm h5 {
    font-size: 14px;
    font-weight: bold;
  }
  .text-map {
    font-size: 12px;
  } */
  .icono__Cv img {
    width: 120%;
  }
  .icono__Cv p {
    font-size: 12px;
  }
}

/*ICONOS Mapa Rutas*/
.icono-map1 {
  color: red;
}
.icono-map2 {
  color: green;
}

.text-map {
  font-size: 1.8vw;
  padding: 20px;
  color: #002856;
}

.terminal {
  padding: 20px;
}

/*ICONOS Mapa Rutas*/

/*CARD BLOQUE 5 TRONCALES */

.card__Troncales {
  border: 2px solid #002856;
  border-radius: 20px;
  margin-bottom: 50px; /*espacio vertical */
  padding: 23px;
}

.card__Troncales h3 {
  font-size: 2.5vw;
  font-weight: bold;
  color: #002856;
  margin-top: 40px;
  margin-left: 40px;
}
.card__Troncales img {
  width: 10vw;
  color: #e30613;
}
.card__Troncales li {
  color: #002856;
  font-size: 18px;
}
.card__Troncales span {
  font-weight: bold;
}
.card__Troncales ol {
  margin-top: 40px;
}
/* .cars-ico {
  background-color: #e30613;
  width: 10vw;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: 40px;
} */


.cars-ico-1 {
  background-color: green;
  width: 10vw;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: 40px;
}
/* .cars-ico img {
  width: 10vw;
} */
/*Cards*/
.container__card-noti {
  width: 100%;
  display: flex;
  /* max-width: 1100px; */
  margin: auto;
  margin-bottom: 50px;
  margin-top: -20px;;
}
.title-noti {
  /* width: 100%;
  max-width: 1080px;
  margin: auto;
  padding: 20px;
  margin-top: 20px; */
  display: flex;
  margin-top: 20px;
  

}
.title-noti h2 {
  /* font-size: 50px; */
  font-weight: bold;
  color: #00519E;
}
.title-noti P {
  /* font-size: 50px; */
  font-weight: bold;
  color: #00519E;
  text-align: right;
  padding-top:5px;
  align-items: center;
}
.cards {
  width: 100%;
  margin: 10px;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  transition: all 400ms ease-out;
  cursor: default;
}
.cards:hover {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3%);
}
/* .cards img {
  width: 100%;
  height: 210px;
} */
.cards .contenido-card-noti {
  padding: 15px;
  /* text-align: center; */
}

.cards .contenido-card-noti h5 {
  margin-bottom: 15px;
  color: #002856;
}
.cards .contenido-card-noti p {
  line-height: 1.8;
  color: #002856;
  /* font-size: 14px; */
  margin-bottom: 5px;
}
.cards .contenido-card-noti a {
  display: inline-block;
  padding: 5px;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  border: 1px solid #00a13a;
  background-color: #00a13a;
  border-radius: 30px;
  transition: all 400ms ease;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  width: 150px;
}
.cards .contenido-card-noti a:hover {
  background: #e30613;
  border: 1px solid #e30613;
  color: #fff;
}


@media only screen and (min-width: 320px) and (max-width: 768px) {
  .container__card-noti {
    flex-wrap: wrap;
  }
  .cards {
    margin: 15px;
  }
}

.linea-colores1 {
  width: 40%;
  height: 5px;
  background-image: linear-gradient(90deg, #e30613, #ffdd00, #ffdd00, #00a13a);
  margin-bottom: 10px;
  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
}

@media only screen and (max-width: 600px) {
  div.linea-colores1 {
    margin-top: -1px;
  }
}
/*Fin-Cards*/


/* 
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .wrapper {
    
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: minmax(100px, auto);
  }
  .wrapper {
    margin: 0 auto;
    display: center;
    width: 250px;
  }

  .wave__card img {
    width: 40px;
  }
  .wave__card h6 {
    font-size: 13px;
    margin-bottom: 0;
    overflow-wrap: normal !important;
  }
  .wave__card-group {
    width: 100% !important;
    padding-left: 0px;
    margin-top: 20px;
  }
  .moovit__card {
    padding-left: 60px;
  }
  .wave__card {
    max-width: 150px;
    width: 100%;
    max-height: 140px;
    margin: 0 auto;
  }
} */


/* Social Lateral */


.red {
  position: fixed;
  top: 200px;
  z-index: 5000;
  /* left: 0; */
  right: 0;
}

#facebook a {
  background: #3b5998;
  /* padding: 10px 25px; */
  padding: 7px 29px;
  color: #fff;
  text-decoration: none;
  width: 55px;
  transition: all 0.4s ease;
  border-top-left-radius: 10px;
}
#facebook a:hover {
  /* background: #000; */
  padding: 10px 50px;
  margin-left: -45px;
}
#youtube a {
  background: #bb0000;
  /* padding: 10px 20px; */
  padding: 4px 24px;
  color: #fff;
  text-decoration: none;
  width: 55px;
  transition: all 0.4s ease;
}
#youtube a:hover {
  /* background: #000; */
  padding: 10px 50px;
  margin-left: -45px;
}
#twitter a {
  background: #1d1d1b;
  /* padding: 10px 20px; */
  padding: 0px 24px;
  padding-bottom: 12px;
  color: #fff;
  text-decoration: none;
  width: 55px;
  transition: all 0.4s ease;
}
#twitter a:hover {
  /* 
   */
  padding: 10px 50px;
  margin-left: -45px;
}
#instagram a {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
  /* padding: 10px 20px; */
  padding: 5px 24px;
  color: #fff;
  text-decoration: none;
  width: 55px;
  transition: all 0.4s ease;
  border-bottom-left-radius: 10px;
}
#instagram a:hover {
  /* background: #000; */
  color: #fff;
  padding: 10px 50px;
  margin-left: -45px;
}

/* #tiktok a {
  background: #0b0c0c;
  padding: 10px 24px;
  color: #fff;
  text-decoration: none;
  width: 55px;
  transition: all 0.4s ease;
}
#tiktok a:hover {
  padding: 10px 50px;
  margin-left: -45px;
} */

#tiktok a {
  background: #0b0c0c;
  /* padding: 10px 20px; */
  padding: 5px 24px;
  color: #fff;
  text-decoration: none;
  width: 55px;
  transition: all 0.4s ease;
}
#tiktok a:hover {
  /* background: #000; */
  padding: 10px 50px;
  margin-left: -45px;
}

#whatsapp a {
  background: #1ba149;
  padding: 5px 24px;
  color: #fff;
  text-decoration: none;
  width: 55px;
  transition: all 0.4s ease;
}
#whatsapp a:hover {
  /* 
   */
  padding: 10px 50px;
  margin-left: -45px;
}

.arriendo img {
  width: 50%;

}
@media only screen and (min-width: 320px) and (max-width: 768px) {
.arriendo img {
  width: 80% !important;

}
}

/* infra css */

.mapa_troncal {
  margin-top: 0px;
  background-color: #F5F5F5;
  
  /* height: 300px; */
}

.mapa_troncal .card {
  background-color: #F5F5F5;
}
.mapa_troncal h2 {
  font-weight: bold;
  color: #00519E;
  margin-top: 50px;
}

.mapa_troncal-linea {
  width: 100px;
  height: 5px;
  background-image: linear-gradient(90deg, #e30613, #ffdd00, #ffdd00, #00a13a);
  margin-bottom: 5px;
  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
}


.mapa_troncal-gmap{
  margin-top: -20px; 
}

.mapa_troncal-gmap iframe {
 width: 100%;
 height: 350px;
}

 .mapa_troncal-btn .baseTab{ 
  cursor: pointer;
    margin: 10px 0 10px 0;
    
 
 border-radius: 20px;
 color:#fff;
 
}

.mapa_troncal-btn {
  display: flex;  
  align-items: center;
  justify-content: center;
}


/* Estaciones */
/* Troncal Murillo */
.estaciones h2{
  font-weight: bold;
  color: #00519E;
  margin-top: 50px;
}

.estaciones p {
  color: #002856;
  
}
.estaciones a {
  
  text-decoration: none;
  color:#002856;
  /* font-weight: 600; */
  
}
.estaciones a span{ 
  color:#00a13a;
}
.estaciones li {
  color:#002856;
  font-weight: 600;
  /* padding-bottom: 10px; */
  line-height: 2;
}
.card_personalizacion {
    border-radius: 10px;
}
.card_personalizacion .card-body {
  background-color: #00519E;
  border-radius: 0 0 10px 10px ;
}

.card_personalizacion p {
  color: #fff
}

.card_personalizacion img {
  border-radius: 10px 10px 0 0;
}

.card_personalizacion a {
  /* background-color: ;
  color: #fff;
  border-radius: 10px; */
  display: inline-block;
  padding: 5px;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  border: 1px solid #00a13a;
  background-color: #00a13a;
  border-radius: 30px;
  transition: all 400ms ease;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  width: 200px;
}


.card_personalizacion a:hover {
  background: #e30613;
  border: 1px solid #e30613;
  color: #fff;
}

.card_personalizacion .linea-colores-perso {
  width: 35%;
  height: 4px;
  margin-top: -5px;
  
  background-image: linear-gradient(
    90deg,
    #e30613 33%,
    #ffdd00 33%,
    #ffdd00 66.66%,
    #00a13a 66.66%
  );
  /*espacio vertical */
  /* margin-bottom: 15px; */
  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
}

/* icon Troncal Murillo */
.icon_troncal {
  width: 150px;
  height: 250px;
  background-color: #e30613;
  border-radius: 10px;
}

.icon_troncal p {
  color: #fff;
  font-weight: bold;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: -10px;
}
.icon_troncal img {
  width: 120px;
}
/* Troncal olaya */

/* Troncal Olaya */
.estaciones_olaya h2{
  font-weight: bold;
  color: #00519E;
  margin-top: 50px;
}

.estaciones_olaya p {
  color: #002856;
  
}
.estaciones_olaya a {
  
  text-decoration: none;
  color:#002856;
  /* font-weight: 600; */
  
}
.estaciones_olaya a span{ 
  color:#e30613;
}
.estaciones_olaya li {
  color:#002856;
  font-weight: 600;
  /* padding-bottom: 10px; */
  line-height: 2;
}
.card_pqrs-olaya {
    border-radius: 10px;
    background-color: #fff;
    border-color: #fff;
}
.card_pqrs-olaya .card-body {
  background-color: #fff;
  border-radius: 0 0 10px 10px ;
}

.card_pqrs-olaya p {
  color: #fff
}

.card_pqrs-olaya img {
  border-radius: 10px 10px 0 0;
}

.card_pqrs-olaya a {
  display: block;
  padding: 5px;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  border: 1px solid #e30613;
  background-color: #e30613;
  border-radius: 30px;
  transition: all 400ms ease;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  width: 250px;
  margin:  auto;

}


.card_pqrs-olaya a:hover {
  background: #00a13a;
  border: 1px solid #00a13a;
  color: #fff;
}

/* icon Troncal olaya */
.icon_troncal-olaya {
  width: 150px;
  height: 250px;
  background-color: #00a13a;
  border-radius: 10px;
}

.icon_troncal-olaya p {
  color: #fff;
  font-weight: bold;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: -10px;
}
.icon_troncal-olaya img {
  width: 120px;
}

/* Rutas alimentadoras */
.rutas_alimentadoras {
  background-color: #F5F5F5;
  margin-top: 50px;
}

.rutas_alimentadoras p {
  color: #002856;
  
}
.rutas_alimentadoras a {
  
  text-decoration: none;
  color:#002856;
  /* font-weight: 600; */
  
}

.rutas_alimentadoras li {
  color:#002856;
  font-weight: 600;
  /* padding-bottom: 10px; */
  line-height: 2;
  list-style: none;
}

.rutas_alimentadoras a span  {
  color:#002856;
  font-weight: 700;
  /* padding-bottom: 10px; */
  
  
}
/* icon Troncal Olaya */
.rutas_alimentadoras .icon_alimentadoras {
  width: 200px;
  height: 250px;
  background-color: #ffdd00;
  border-radius: 10px;
}

.rutas_alimentadoras .icon_alimentadoras p {
  color: #fff;
  font-weight: bold;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: -10px;
}
.rutas_alimentadoras .icon_alimentadoras img {
  width: 120px;
}

/* Rutas alimentadoras */
.alimentadora__card {
  color: #002856;
  margin-top: 50px;
}

.alimentadora__card .col-md-6 {
  
  background: linear-gradient(to top, #F5F5F5 0%, #F5F5F5 90%, white 50%, white 100% );
}
.alimentadora__card h2 {
  margin-left: 50px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #00519E;
  margin-top: 14.5vw; 
}
.alimentadora__card p {
  padding-right: 20px;
  margin-left: 50px;
}

.alimentadora__card img {
  height: 70vh;
  width: 100vw;
}

.alimentadora__card .linea-colores-ali {
  width: 35%;
  height: 4px;
  margin-bottom: 20px;
  margin-left: 50px;
  background-image: linear-gradient(
    90deg,
    #e30613 33%,
    #ffdd00 33%,
    #ffdd00 66.66%,
    #00a13a 66.66%
  );
  /*espacio vertical */
  /* margin-bottom: 15px; */
  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
}


@media only screen and (min-width: 320px) and (max-width: 768px) {
  .alimentadora__card h2 {
    margin-top: 25px;
  }
  .alimentadora__card .col-md-6 {
  
    background: linear-gradient(to top, #F5F5F5 0%, #F5F5F5 82.5%, #F5F5F5 50%, #F5F5F5 100% );
  }
  .alimentadora__card img {
    height: 50vh;
    
  }
}
@media only screen and (min-width: 320px) and (max-width: 1142px) {
  .estaciones  .col-md-2 {
    margin: auto;
  }
  .estaciones  .col-md-6 {
    margin: auto;
  }
  .estaciones  .col-md-4  .card_personalizacion {
    margin: auto;
  }
  
  
  
  .estaciones_olaya  .col-md-2 {
    margin: auto;
  }
  .estaciones_olaya  .col-md-6 {
    margin: auto;
  }
  .estaciones_olaya  .col-md-4 .card_pqrs-olaya {
    margin: auto;
  }


 /*  .rutas_alimentadoras  .col-md-2 {
    margin: auto;
  } */
 /*  .rutas_alimentadoras  .col-md-6 .col-md-6 {
    margin: auto;
  }
   */

}


#alimentadora_img {
  background-image: url("https://apiwebtm.com/uploads/img_rutas_alimentadoras_c8fa5f7de0.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  #acerca_img {
    /* width: 50vw; */
    height: 300px;
    /* margin: 0 auto; */
  }


}
.adetail__cards {
  margin-top: 50px;
  margin-bottom: 50px;
}

.adetail__cards h2 {
  /* font-weight: bold; */
  color: #00519E;
}
.adetail__cards span {
  color: #e30613;
  font-weight: bold;
}





@media only screen and (min-width: 200px) and (max-width: 568px) {
  .seleccion-rutas .row {
    max-width: none;
  }
}

/* alimentador rutas mapa */

.map-alimentadora {
  border: 3px solid #e30613;
  max-width: 100%;
  height: 383px;
  margin-top: 50px;
}

/* Horario y sitio */

.horario-sitio {
  margin-top: 50px;
  margin-bottom: 50px;
}

/* Tabla */

.tabla-alimentadoras .card{
  border-radius: 25px;
  border: 3px solid #e30613;

}
.tabla-alimentadoras .card-text{
  color: #002856;

}
.tabla-alimentadoras h2 {
  
  color: #00519E;
}


/* Rediseño alimentadoras */

.alidetail__cards {
  margin-top: 50px;
  margin-bottom: 50px;
}
.alidetail__cards i {
  color: #00519E;
  margin-bottom: 20px;
}
.alidetail__cards h5 {
  font-weight: bold;
  color: #002856;
}
.alidetail__cards span {
  color: #002856;
  font-weight: bold;
}



.horario-sitio ul {
  margin-top: 20px;
  color: #002856 !important;
  
  
}
.horario-sitio .card {
  margin-bottom: 10px;
}

#accordionFlushAlimentadora button {
  color: #fff !important;
  font-weight: bold !important;
  background-color: #00519E !important;
}

#accordionFlushAlimentadora .card-text {
  color: #002856 !important;
  
}
#accordionFlushAlimentadora .accordion-button::after { 
  background-color: #00519E !important;
  background-image: url(https://apiwebtm.com/uploads/white_down_arrow_png_2_c823ab38c6.png);
  /* border-radius: 20px; */
}
.troncaldetail__cards {
  margin-top: 50px;
  margin-bottom: 50px;
}
.troncaldetail__cards h2 {
  font-weight: bold;
  color: #00519E;
  
}
.troncaldetail__cards h5 {
  font-weight: bold;
  color: #002856;
  margin-top: 20px;
}
.troncaldetail__cards span {
  color: #002856 ;
  font-weight: bold;
}

.troncaldetail__cards i {
  
  color: #002856;
  margin-top: 20px;
}
.troncaldetail__cards p {
  color: #002856;
  margin-top: 20px;
  
}
.troncaldetail__cards .card-body {
  padding: 40px;
}
@media only screen and (min-width: 200px) and (max-width: 568px) {
  .seleccion-rutas .row {
    max-width: none;
  }
}

/* alimentador rutas mapa */

.map-alimentadora {
  border: 3px solid #e30613;
  max-width: 100%;
  height: 583px;
  margin-top: 50px;
}

/* Horario y sitio */

.horario-sitioTroncal {
  margin-top: 50px;
  margin-bottom: 50px;
  
}

.horario-sitioTroncal ul {
  margin-top: 20px;
  color: #002856 !important;
  
  
}
.notfound-social .fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .notfound-social .fa-twitter:before {
    content: "\f099";
  }
  .notfound-social .fa-facebook:before {
    content: "\f09a";
  }
  .notfound-social .fa-pinterest:before {
    content: "\f0d2";
  }
  .notfound-social .fa-google-plus:before {
    content: "\f0d5";
  }
  /*! CSS Used from: https://colorlib.com/etc/404/colorlib-error-404-16/css/style.css */
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  #notfound {
    position: relative;
    height: 100vh;
  }
  #notfound .notfound-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-image: url("https://colorlib.com/etc/404/colorlib-error-404-16/img/bg.jpg"); */
    background-size: cover;
  }
  #notfound .notfound-bg:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .notfound {
    max-width: 910px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
  }
  .notfound .notfound-404 {
    position: relative;
    height: 200px;
  }
  .notfound .notfound-404 h1 {
    font-family: "Montserrat", sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 220px;
    font-weight: 900;
    margin: 0px;
    color: #e30613;
    text-transform: uppercase;
    letter-spacing: 10px;
  }
  .notfound h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: #e30613;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .notfound .home-btn,
  .notfound .contact-btn {
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid transparent;
    text-transform: uppercase;
    padding: 13px 25px;
    font-size: 18px;
    border-radius: 40px;
    margin: 7px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
  }
  .notfound .home-btn:hover,
  .notfound .contact-btn:hover {
    opacity: 0.9;
  }
  .notfound .home-btn {
    color: #fff;
    background: #e30613;
  }
  .notfound .contact-btn {
    border: 2px solid #e30613;
    color: #e30613;
  }
  .notfound-social {
    margin-top: 25px;
  }
  .notfound-social > a {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 14px;
    color: #e30613;
    margin: 0px 6px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
  }
  .notfound-social > a:hover {
    color: #fff;
    background-color: #e30613;
    border-radius: 50%;
  }
  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 h1 {
      font-size: 182px;
    }
  }
  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
      height: 146px;
    }
    .notfound .notfound-404 h1 {
      font-size: 146px;
    }
    .notfound h2 {
      font-size: 16px;
    }
    .notfound .home-btn,
    .notfound .contact-btn {
      font-size: 14px;
    }
  }
  /*! CSS Used fontfaces */
  @font-face {
    font-family: "FontAwesome";
    src: url("https://colorlib.com/etc/404/colorlib-error-404-16/fonts/fontawesome-webfont.eot?v=4.7.0");
    src: url("https://colorlib.com/etc/404/colorlib-error-404-16/fonts/fontawesome-webfont.eot#iefix&v=4.7.0")
        format("embedded-opentype"),
      url("https://colorlib.com/etc/404/colorlib-error-404-16/fonts/fontawesome-webfont.woff2?v=4.7.0")
        format("woff2"),
      url("https://colorlib.com/etc/404/colorlib-error-404-16/fonts/fontawesome-webfont.woff?v=4.7.0")
        format("woff"),
      url("https://colorlib.com/etc/404/colorlib-error-404-16/fonts/fontawesome-webfont.ttf?v=4.7.0")
        format("truetype"),
      url("https://colorlib.com/etc/404/colorlib-error-404-16/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://colorlib.com/fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://colorlib.com/fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://colorlib.com/fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://colorlib.com/fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
 /*  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://colorlib.com/fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  } */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://colorlib.com/fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://colorlib.com/fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://colorlib.com/fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://colorlib.com/fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://colorlib.com/fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }
  
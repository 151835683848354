/* .searchInputs {
    margin-top: 105px;
    display: flex;
  } */
  
  .search {
     width: 180px;
     position: relative
    }
 /*  .search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 30px;
    width: 300px;
    border: 1px solid black;
  } */
  
/*   .searchIcon {
    height: 60px;
    width: 50px;
    background-color: white;
    display: grid;
    place-items: center;
  }
  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 35px;
  }
  */
  .dataResult {
    margin-top: 5px;
    width: 180px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 3;
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
    
  }
  
  .dataResult .dataItem {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    color: #002856;
    
  }
  /*
  .dataItem p {
    margin-left: 10px;
  }*/
  
 .search a {
    text-decoration: none;
  }
  
 .search a:hover {
    background-color: lightgrey;
  }
  .search p {
      padding: 5% 5% 5% 5% ;
      color: #002856;
      font-size: 14px;
      margin-bottom: 1px;
  }
  /*
  #clearBtn {
    cursor: pointer;
  } */



  .input-box{
    position: relative;
    
    
    
  }



  .search-click {
    border: 1px solid #f5f5f5;
    outline: none;
    background-size: 22px;
    background-position: 13px;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    padding-left: 35px;
    transition: all 0.5s;
  }

  /* Tamaño search */
  .search-click:focus {
    width: 180px;
    padding-left: 20px;
    border: 1px solid #f5f5f5;
  }
  .search-click {
    position: relative;
    overflow: hidden;
    height: 50px;
  }
  .search-click input {
    background: transparent;
    border: 1px solid #f5f5f5;
    outline: none;
    position: absolute;
    width: 250px;
    height: 50px;
    left: 0%;
    padding: 10px;
  }
  
 /*  .input-box input::placeholder {
    color: rgba(255,255,255,.5);
   }

   .input-box input:hover::placeholder {
   color: rgba(17, 16, 16, 0.5);
    
  } */

/* icon */
  .input-box i {
    position: absolute;
    right: 16px;
    top:13px;
    color:#00519E;
  
  }
  
  .form-control{
  
    height: 40px;
    background-color:#fff;
  }
  
  .form-control:focus{
    background-color: #eeeeee69;
    box-shadow: none;
    border-color: #eee;
    border: 1px solid #ccc;
  }
 
.navbar__card {
  background-color: #fff;
}
.navbar__card li {
  color: #004f9f;
 
 
  
margin-left: 0.625rem;
}
.navbar__card a {
  color: #004f9f !important;
  text-decoration: none;
   
}

.navbar__card a:hover {
  color: #e30613 !important;
}


.navbar__card img { 
  max-width: 274px;
  /* margin-left: 1.3vw; */
    max-height: 73px;
  /* margin-right: 10px; */
}

/* @media only screen and (width: 1024px) {
  .navbar__card a {
    font-size: .6rem;
  }
}
@media only screen and (width: 2560px) {
  .navbar__card a {
    font-size: 1.8rem;
  }
} */
/* css Rediseño */

/* @media sreem */
@media screen and (max-width: 992px) {
  img {
    width: 232px;
    height: 61px;
    
  }
 
}


/* css header to hide on scroll */
.visible {
  /* display: flex; */
  top: 0; 
  transition: top 0.4s ease-out;
}
.hidden {
  /* display: none; */
  top: -30px;
  transition: top 0.4s ease-out;
}
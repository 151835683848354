.cd {
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  transition: all 400ms ease-out;
  cursor: default;
  margin-top: 50px;
  margin-bottom: 50px;
}

.cd h2 {
  /* font-size: 35px; */
  color: #002856;
  position: relative;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.cd h5 {
  color: #002856;
  position: relative;
  margin-bottom: 30px;
}
.cd p {
  color: #002856;
}

.cd li {
  margin-bottom: 24px;
  margin-top: 15px;
  color: #002856;
}
.cd i {
  color: #c00d0d;
  margin-top: 5px;
  font-size: 20px;
}
.cd a {
  text-decoration: none;
  margin-top: 10px;
  font-weight: bold;
}

.linea-colores {
  width: 50%;
  height: 5px;
  background-image: linear-gradient(
    90deg,
    #e30613 33%,
    #ffdd00 33%,
    #ffdd00 66.66%,
    #00a13a 66.66%
  );
  margin-bottom: 60px;
  /*espacio vertical */
  margin-top: 60px;
  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
  margin-bottom: 60px;
  /*espacio vertical */
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  div.linea-colores {
    width: 300px;
    margin-top: 50px;
  }
}

/* Objetivos 1 y 2 */
.obj-ali {
  border: 2px solid #c00d0d;
  border-radius: 30px;
  margin-bottom: 70px;
  /* width: 100%; */
  margin-top: 60px;
}
.obj-ali1 {
  border: 2px solid #c00d0d;
  border-radius: 30px;
  border-left: none;
  border-top: none;
  border-bottom: none;
  color: #c00d0d;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
}

.obj-ali2 {
  color: #c00d0d;
  padding-left: 50px;
}

.obj-ali2 li {
  padding-top: 15px;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 10px;
  list-style: none;
}

@media only screen and (min-width: 200px) and (max-width: 568px) {
  
  
  
  .obj-ali1 {
    border: 3px solid #c00d0d;
    border-radius: 12px;
    border-right: none;
    border-top: none;
    border-left: none;
  }
  .obj-ali2 li {
    padding-top: 15px;
    padding-right: 55px;
    padding-left: -5px;
    /* margin-left: 20px; */
    
  }

  .obj-ali2 {
    border: 3px solid #c00d0d;
    border-radius: 12px;
    border-right: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
  }
  .obj-ali3 {
    border: 3px solid rgb(17, 0, 80);
    border-radius: 12px;
    border-right: none;
    border-left: none;
    border-bottom: none;
  }
}

/* Objetivos 3 y 4 */
.obj_ali {
  border: 2px solid #002856;
  border-radius: 30px;
  margin-bottom: 70px;
  /* width: 100%; */
  margin-top: 60px;
}
.obj-ali3 {
  border: 2px solid #002856;
  border-radius: 30px;
  border-left: none;
  border-top: none;
  border-bottom: none;
  color: #002856;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
}

.obj-ali4 {
  color: #002856;
  padding-left: 50px;
}

.obj-ali4 li {
  padding-top: 15px;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 10px;
  list-style: none;
  margin-bottom: 15px;
}

@media only screen and (min-width: 200px) and (max-width: 568px) {
  .obj-ali3 {
    border: 3px solid #002856;
    border-radius: 12px;
    border-right: none;
    border-top: none;
    border-left: none;
  }
  .obj-ali4 li {
    padding-top: 15px;
    padding-right: 55px;
    padding-left: -5px;
  }

  .obj-ali4 {
    border: 3px solid #002856;
    border-radius: 12px;
    border-right: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
  }
}

/* Cubos de informacion 1 */

.cubo {
  background-color: #e30613;
  /* max-width: 90%; */
  width: 80vw;
  border: 0px 1px 1px 1px;
  border-radius: 0 27px 27px 0px;
  z-index: 2;
}

.cubo .text-cubo {
  padding: 59px;
  padding-left: 100px;
}

.cubo__Info {
  margin-bottom: 100px;
}
.cubo__Info h5 {
  margin-bottom: 20px;
  font-weight: bold;
}
.cubo__Info p {
  text-align: justify;
}
.cubo__Info span {
  font-weight: bold;
}

.cubo__Info img {
  width: 10vw;
}

/* 2 */

.cubo-2 {
  background-color: #ffffff;
  border-bottom: 0;
  border-right: 0;
  border-radius: 0px;
  border-color: #002856;
  position: relative;
  z-index: 1;
  /* max-width: 90%; */
  width: 80vw;
  margin-top: -211px;
  padding-top: 240px;
  padding-bottom: 140px;
}

.cubo-2 .text-cubo-2 {
  padding: 59px;
  padding-right: 100px;
  color: #002856;
}
.cubo-2 img {
  margin-left: 50px;
}
/* 3 */

.cubo-3 {
  background-color: #004f9f;
  border: 0px 1px 1px 1px;
  border-radius: 0 27px 27px 0px;
  z-index: 2;
  width: 80vw;
  margin-top: -73px;
}

.cubo-3 .text-cubo {
  padding: 59px;
  padding-left: 100px;
}

/* cubo-4 */

.cubo-4 {
  border-top: 0;
  border-right: 0;
  border-radius: 0px;
  border-color: #002856;
  position: relative;
  z-index: 1;
  width: 80vw;
  padding-top: 300px;
  padding-bottom: 240px;
  margin-top: -211px;
}

.cubo-4 .text-cubo-4 {
  padding: 59px;
  padding-right: 100px;
  color: #002856;
}

.cubo-4 img {
  margin-left: 50px;
}

/* cubo 5 */

.cubo-5 {
  background-color: #e30613;
  border: 0px 1px 1px 1px;
  width: 80vw;
  border-radius: 0 27px 27px 0px;
  z-index: 2;
  margin-top: -143px;
}

.cubo-5 .text-cubo {
  padding: 59px;
  padding-left: 100px;
}

@media only screen and (min-width: 200px) and (max-width: 568px) {
  .cubo .text-cubo {
    padding: 40px;
    padding-left: 50px;
  }
  .cubo-2 .text-cubo-2 {
    padding: 40px;
    padding-left: 50px;
  }
  .cubo-3 .text-cubo {
    padding: 40px;
    padding-left: 50px;
  }
  .cubo-4 .text-cubo-4 {
    padding: 40px;
    padding-left: 40px;
  }
  .cubo-5 .text-cubo {
    padding: 40px;
    padding-left: 50px;
  }
  .cubo img {
    margin-left: 120px;
    margin-bottom: 40px;
  }
  .cubo-3 img {
    margin-left: 120px;
    margin-bottom: 40px;
  }
  .cubo-5 img {
    margin-left: 120px;
    margin-bottom: 40px;
  }
}

.rojo-p {
  background-color: #e30613;
}

.rojo-lt {
  color: #e30613;
}

.azul-p {
  background-color: #004f9f;
}

.azul-lt {
  color: #002856;
}

.amarillo-uni {
  background-color: #ffdd00;
}

.verde-uni {
  background-color: #00a13a;
}




/*  Rediseño */

/* manual del usuario  */

.manual__card {
  color: #002856;
  margin-top: 50px;
 
 
}

.manual__card .card {
  background-color: #F5F5F5;
}
.manual__card h2 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #00519E;
  
}
.manual__card p {
  padding-right: 30px;
  font-style: oblique;
}

.manual__card img {
  /* height: 65vh; */
  width: 25vw;
  
}
.manual__card a {
  background-color: #00a13a;
  color: #fff;
  border-radius: 20px;
  padding: 5px;
  width: 250px;
}
.manual__card a:hover {
  background-color: #c00d0d;
  color: #fff;
 border: 1px solid #c00d0d;
}

.manual__card .linea-colores {
  width: 20%;
  height: 5px;
  background-image: linear-gradient(
    90deg,
    #e30613 33%,
    #ffdd00 33%,
    #ffdd00 66.66%,
    #00a13a 66.66%
  );
   
  
  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
  
  /*espacio vertical */
  margin-top: -10px;
}
#manual_img {
 /*  background-image: url("https://apiwebtm.com/uploads/cultura_ef186cfcf6.png"); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; */
  
  margin-top: -10px;
  /* display: block; */
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  #manual_img {
   
    margin-top: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  #manual_img img { width: 50vh}

}

/* Preguntas frecuentes */

.preguntas-tm h1 {
  margin: 0;
  line-height: 2;
  text-align: center;
}

.preguntas-tm h2 {
  margin: 0 0 0.5em;
  font-weight: normal;
}

.preguntas-tm input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.preguntas-tm .row {
  display: flex;
}

.preguntas-tm .row .col {
  flex: 1;
}

.preguntas-tm .row .col:last-child {
  margin-left: 1em;
}

/* Accordion styles */

.preguntas-tm {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.144);
  border: 1px solid #ffdd00;
  border-radius: 15px;
}

.preguntas-tm .preguntas-grupo {
  width: 100%;
  color: #002856;
  overflow: hidden;
}

.preguntas-tm .preguntas-label {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  font-weight: bold;
  cursor: pointer;
  border-radius: 15px;
  padding: 15px;
  /* font-size: 18px; */
  border-bottom: 1px solid #fff;
  /* Icon */
}

.preguntas-tm .preguntas-label:hover {
  background: #ffdd00;
}

.preguntas-tm .preguntas-label::after {
  content: '\276F';
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}

.preguntas-tm .respuestas-content {
  max-height: 0;
  padding: 0 1em;
  background: white;
  transition: all 0.35s;
  color: #004f9f;
  /* font-size: 18px; */
}
.preguntas-tm .respuestas-content span {
  font-weight: bold;
}
.preguntas-tm .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #ffffff;
  cursor: pointer;
}

.preguntas-tm .tab-close:hover {
  background: #ffdd00;
}

.preguntas-tm input:checked + .preguntas-label {
  background: #ffdd00;
}

.preguntas-tm input:checked + .preguntas-label::after {
  transform: rotate(90deg);
}

.preguntas-tm input:checked ~ .respuestas-content {
  /* max-height: 100vh; */
  max-height: 100%;
  max-width: auto;
  padding: 1em;
}

.sin-border {
  border: none;
}

.titulo-preguntas {
  margin: 5%;
  margin-top: 100px;
}
.titulo-preguntas h2 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #00519E;
}
#glosario {
  font-size: 25px;
  /* background-color: #00a13a;  */
  
  
}

#glosario:hover {
  background-color: #00a13a !important; 
  color: #fff;
  
}
@media only screen and (min-width: 200px) and (max-width: 768px) {
  .preguntas-tm input:checked ~ .respuestas-content {
    /* max-height: 400vh; */
    max-width: auto;
    padding: 1em;
  }
}


/* ---- Encuesta ------ */

.card__Encuesta {
  /* width: 45vw; */
  border-radius: 20px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  transition: all 400ms ease-out;
  cursor: default;
  margin-top: 40px;
  margin-bottom: 50px;
  /* display:flex;
  align-items: center;
  justify-content: center */
}

.card__Encuesta h2 {
  
  color: #002856;
  position: relative;
  margin-bottom: 5px;
  
  margin-top: 10px;
  
  font-weight: bold;
  
}

.card__Encuesta button {
  margin-bottom: 10px;
  border: 1px solid #00a13a;
 
  border-radius: 20px;
}
/* .card__Encuesta img {
  width: 11vw !important;
  margin-top: 20px;
  
} */

#encuensta_img {
  background-image: url("https://apiwebtm.com/uploads/Encuesta_7366385556.png");
  background-repeat: no-repeat;
  background-size: 160px;
  background-position: center center;
  
 
}


@media only screen and (min-width: 320px) and (max-width: 768px) {
  .card__Encuesta img {
    /* width: 20% !important;
    margin-bottom: 25px; */
    /* display: none; */
  }
  .card__Encuesta {
    width: 300px;
  }
}


/* Estimulo estudiantil  */

/* .estudent__card {
  color: #002856;
 
} */

.estuden__card  .card {
  background-color: #00519E;
 
}
.estuden__card  h2 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  margin-top: 50px;
  
}
.estuden__card  p {
  padding-right: 30px;
  font-style: oblique;
  color: #fff
}

.estuden__card  img {
  /* height: 80vh; */
  width: 25vw;
}

.estuden__card-benef{
  margin-top: -16px;
  
}
.estuden__card-benef  p {
  
  color: #fff;
  margin-top: 25px;
  margin-bottom: 25px;
 
}
.estuden__card-info {
  margin-bottom: 50px;
}
.estuden__card-info span {
  font-weight: bold;
}
.estuden__card-info  p {
  background-color: #c00d0d;
  color: #fff;
  /* line-height: 1em; */ 
}
.estuden__card-info .card-body {
  border-left: 1px solid #FFFFFF;
margin-top: 25px;
margin-bottom: 25px;
}
.estuden__card-info a { 
  text-decoration: none;
  color: #FFFF;
  font-size: 16px;
}
.estuden__card-info a:hover { 
  font-weight: bold;
  color: #00a13a;
  
  
}
.estuden__card  .linea-colores {
  width: 20%;
  height: 5px;
  background-image: linear-gradient(
    90deg,
    #e30613 33%,
    #ffdd00 33%,
    #ffdd00 66.66%,
    #00a13a 66.66%
  );
   
  
  clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
  
  /*espacio vertical */
  margin-top: -10px;
}
#manual_img {
 /*  background-image: url("https://apiwebtm.com/uploads/cultura_ef186cfcf6.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  
  margin-top: -10px;
  display: block;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  #estuden_img {
   
   display: none;
  }
  #estuden_img img { width: 50vh}

}